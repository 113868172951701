import React, { useState, useEffect, createRef } from 'react';
import { Link } from 'gatsby';

import {
    StyledNewsItemTop,
    StyledNewItemTitle,
    StyledNewsItem,
    StyledNewsList,
    StyledMobileBottomBlock,
} from './list.styled';
import { useLazy } from '../../helpers/hooks';

const NewsItem = ({
    item: {
        title,
        _meta: { uid: slug },
        type,
    },
}) => {
    const [animateStart, setAnimateStart] = useState(true);
    const ref = createRef();
    const loaded = useLazy(ref);
    useEffect(() => {
        if (loaded) {
            setAnimateStart(false);
        }
    }, [loaded]);

    return title.length ? (
        <StyledNewsItem ref={ref}>
            <StyledNewsItemTop>
                <span>{type}</span>
            </StyledNewsItemTop>
            <StyledNewItemTitle animateStart={animateStart}>
                <Link to={`/news/${slug}`}>
                    <h3>
                        <span>{title[0].text}</span>
                    </h3>
                </Link>
            </StyledNewItemTitle>
        </StyledNewsItem>
    ) : null;
};

const NewsList = ({ items }) => {
    if (!items || !items.length) return null;

    return (
        <StyledNewsList>
            {items.map((item, i) => (
                <NewsItem key={`newsitem_${i}`} item={item} />
            ))}
            <StyledMobileBottomBlock>
                <div />
                <div />
            </StyledMobileBottomBlock>
        </StyledNewsList>
    );
};

export default NewsList;
