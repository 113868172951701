import React, { useCallback, useEffect, useState } from 'react';
import { get } from 'lodash';
import axios from 'axios';
import Image from '../image';
import {
    StyledFeaturedItem,
    FeaturedImage,
    FeaturedTitle,
} from './index.styled';
import Embed from '../embed';
import { PlayIcon, Link } from '../shared';

let pollingInterval;
let enablePolling = true;
const POLLING_TIMEOUT = 10;

const FeaturedItem = ({ item, restreamerEndpoint }) => {
    const [isLiveStream, setIsLiveStream] = useState(false);
    const restreamerCheck = useCallback(() => {
        axios
            .get(restreamerEndpoint)
            .then(({ data }) => {
                const connectedType = get(
                    data,
                    'repeat_to_local_nginx.type',
                    '',
                );
                if (
                    ['connecting', 'connected', 'error'].includes(connectedType)
                ) {
                    setIsLiveStream(true);
                } else {
                    setIsLiveStream(false);
                }
            })
            .catch(() => {
                setIsLiveStream(false);
                console.error('Error reaching the restreamer server');
            });
    }, [restreamerEndpoint]);
    useEffect(() => {
        if (restreamerEndpoint) {
            restreamerCheck();
            pollingInterval = setInterval(() => {
                if (enablePolling) {
                    restreamerCheck();
                }
            }, POLLING_TIMEOUT * 1000);
        }
        return () => {
            if (pollingInterval) {
                clearInterval(pollingInterval);
            }
        };
    }, [restreamerEndpoint, restreamerCheck]);
    if (!item) {
        enablePolling = false;
        return null;
    }
    enablePolling = true;

    const {
        featuredTitle,
        featuredImage,
        featuredImageSharp,
        item_link: itemLink,
        streamEmbed,
    } = item;
    const url = streamEmbed
        ? { html: get(streamEmbed, '[0].text', '') }
        : false;

    return (
        <StyledFeaturedItem>
            <FeaturedTitle>
                <h1>
                    {isLiveStream ? (
                        'LIVE NOW'
                    ) : (
                        <Link link={itemLink}>
                            {get(featuredTitle, '[0].text', '')}
                        </Link>
                    )}
                    <PlayIcon />
                </h1>
            </FeaturedTitle>
            {isLiveStream ? (
                <Embed embedHeight={588} embedWidth={1046} url={url} />
            ) : (
                <FeaturedImage>
                    <Link link={itemLink}>
                        <Image
                            image={featuredImage}
                            imageSharp={featuredImageSharp}
                        />
                    </Link>
                </FeaturedImage>
            )}
        </StyledFeaturedItem>
    );
};

export default FeaturedItem;
