import React from 'react';
import { graphql } from 'gatsby';
import compareDesc from 'date-fns/compareDesc';
import parseISO from 'date-fns/parseISO';
import { TopCol, BottomCol } from './home/grid';
import NewsList from '../components/news/list';
import LatestRecordings from '@components/latest-recordings';
import FeaturedItem from '@components/featured-item';
import UpcomingShows, {
    groupUpcomingShows,
} from '../components/upcoming-shows';
import FeaturedArtBlock from '../components/featured-art-block';

import {
    HomeWrapper,
    EmbedWrapper,
    UpcomingWrapper,
    LatestRecordingsWrapper,
} from './home.styled';

const combineNewsAndEvents = ({ events = [], news = [] }) => {
    // add type for render option
    const results = [
        ...events.map(e => ({
            ...e.node,
            date: e.node.date_start,
            type: 'event',
        })),
        ...news.map(n => ({ ...n.node, type: 'news' })),
    ];

    // sort by date/date_start DESC
    return results.sort((a, b) =>
        compareDesc(parseISO(a.date), parseISO(b.date)),
    );
};

const HomePage = ({ data, pageContext, navigate }) => {
    if (!data) {
        return null;
    }

    const {
        prismic: { homepage, allNewss, allEvents },
    } = data;

    const newsListItems = combineNewsAndEvents({
        events: allEvents.edges,
        news: allNewss.edges,
    });

    return (
        <HomeWrapper>
            <TopCol>
                <div>
                    <NewsList items={newsListItems.slice(0, 3)} />
                </div>
                <EmbedWrapper>
                    <FeaturedItem
                        item={homepage.featured_item[0]}
                        restreamerEndpoint={pageContext.restreamerEndpoint}
                    />
                </EmbedWrapper>
                <div />
            </TopCol>
            <LatestRecordingsWrapper>
                <LatestRecordings items={pageContext.latestRecordings} />
            </LatestRecordingsWrapper>
            <BottomCol>
                <UpcomingWrapper>
                    <UpcomingShows
                        groupedDates={groupUpcomingShows(
                            pageContext.upcomingPerDate,
                            4,
                            1,
                        )}
                        onShowClick={(i, slug) => {
                            navigate(`/schedule/${slug}`);
                        }}
                        showMore
                    />
                </UpcomingWrapper>
                <FeaturedArtBlock
                    relativeHeight={987}
                    maxHeight={1480}
                    relativeWidth={714}
                    maxWidth={1071}
                    artwork={homepage.artwork}
                />
            </BottomCol>
        </HomeWrapper>
    );
};

export const query = graphql`
    query GetHomePage($uid: String!) {
        prismic {
            homepage(lang: "en-au", uid: $uid) {
                artwork {
                    ... on PRISMIC_Art {
                        title
                        artist
                        image
                        imageSharp {
                            childImageSharp {
                                fluid {
                                    srcSet
                                    src
                                }
                            }
                        }
                        description
                        links {
                            link_url {
                                ... on PRISMIC__ExternalLink {
                                    url
                                }
                            }
                        }
                    }
                }
                featured_item {
                    item_link {
                        ... on PRISMIC_Show {
                            show_image
                            video_url
                            seo_description
                            description
                            date_start
                            date_end
                            title
                            _meta {
                                uid
                            }
                        }
                        ... on PRISMIC_Event {
                            title
                            seo_description
                            date_start
                            date_end
                            location_title
                            _meta {
                                uid
                            }
                        }
                        ... on PRISMIC_News {
                            title
                            seo_description
                            date
                            author
                            _meta {
                                uid
                            }
                        }
                    }
                    streamEmbed: stream_embed
                    featuredTitle: featured_title
                    featuredImage: featured_image
                    featuredImageSharp: featured_imageSharp {
                        childImageSharp {
                            fluid {
                                srcSet
                                src
                            }
                        }
                    }
                }
            }
            allNewss(sortBy: meta_firstPublicationDate_DESC) {
                edges {
                    node {
                        title
                        _meta {
                            uid
                        }
                        date
                    }
                }
            }
            allEvents(sortBy: meta_firstPublicationDate_DESC) {
                edges {
                    node {
                        title
                        _meta {
                            uid
                        }
                        date_start
                    }
                }
            }
        }
    }
`;

export default HomePage;
