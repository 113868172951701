import styled from 'styled-components';
import { generateBreakpoint } from '@layouts/grid';
import {
    generateVwPercentageHeight,
    generateVwPercentageWidth,
} from '@src/helpers';
import artworkCaptionBg from '@src/images/bg/artwork-caption-bg.svg';

export const StyledFeaturedArtBlock = styled.div`
    position: relative;
    overflow: hidden;

    width: 100vw;
    max-width: 100%;
    height: 131.23vw;

    ${generateBreakpoint('lg')(
        `height: auto;
        width: auto;`,
    )}
`;

export const StyledImage = styled.div`
    img {
        position: absolute;
        top: 0;
        left: 0;

        width: 100vw;
        max-width: 100%;
        height: 131.23vw;

        ${props =>
            generateBreakpoint('lg')(
                generateVwPercentageHeight(
                    props.relativeHeight,
                    props.maxHeight,
                ),
            )}
        ${props =>
            generateBreakpoint('lg')(
                generateVwPercentageWidth(props.relativeWidth, props.maxWidth),
            )}
    }
`;

export const StyledCaption = styled.div`
    position: absolute;
    z-index: 2;

    width: 100%;
    height: 89px;
    background-image: url(${artworkCaptionBg});
    background-repeat: repeat-x;

    ${generateBreakpoint('lg')(`width: 66%;`)}
`;

export const StyledInnerCaption = styled.div`
    width: 80%;

    margin-top: 17px;
    margin-left: 17px;
    padding: 7px 11px;
    padding-right: 40px;

    background-color: #000;

    font-size: 14px;
    line-height: 1.71;
    text-transform: uppercase;

    ${generateBreakpoint('lg')(`width: 50%;`)}
`;
