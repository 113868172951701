import styled from 'styled-components';
import { generateBreakpoint } from '@layouts/grid';
import {
    generateVwPercentageHeight,
    generateVwPercentageWidth,
} from '@src/helpers';
import colors from '@shared/colors';

export const FeaturedImage = styled.div`
    position: relative;
    ${generateVwPercentageHeight(167, 381, 320)}
    ${generateVwPercentageWidth(296, 676, 320)}

    img {
        height: 100%;
        width: 100%;
        max-width: auto;
        max-height: auto;
    }

    ${generateBreakpoint('md')(
        `${generateVwPercentageHeight(588)}
    ${generateVwPercentageWidth(1046)}
    border: 1px solid ${colors.brownGrey};
    border-left: 0;`,
    )}
`;

export const FeaturedTitle = styled.div`
    position: absolute;
    top: 0;
    z-index: 3;
    padding: 7px 11px;
    padding-right: 40px;
    font-family: 'SuisseIntl';

    width: calc(100vw - 24px);
    h1 {
        font-size: 14px;
        line-height: 1.71;
        text-transform: uppercase;
    }

    ${generateBreakpoint('md')('width: 100%;')}
`;

export const StyledFeaturedItem = styled.div`
    position: relative;
    width: 100%;
    left: 12px;
    z-index: 3;

    ${generateBreakpoint('md')('left: auto;')}
`;
