import React, { useState } from 'react';
import { RichText } from '@components/shared';
import { TooltipContent, TooltipWrapper, Description } from './tooltip.styled';

import sideMenuDown from '@src/images/icons/side-menu-down-white.svg';
import SideMenu from '../side-menu';
import colors from '@shared/colors';
import { SocialLinks } from '../shared/SocialLinks';

const Tooltip = ({ artwork }) => {
    const [open, setOpen] = useState(false);
    if (!artwork) {
        return null;
    }
    const { description, links } = artwork;
    return (
        <TooltipWrapper>
            <SideMenu
                isOpen={open}
                maxWidth={475}
                buttonProps={{
                    icon: sideMenuDown,
                    color: colors.veryLightPink,
                    bgColor: colors.violetBlue,
                    text: 'more info',
                    onClick: () => setOpen(!open),
                }}
            >
                <TooltipContent>
                    <Description>
                        {description && description.length ? (
                            <RichText render={description} />
                        ) : null}
                    </Description>
                    {links && links.length ? (
                        <SocialLinks links={links} />
                    ) : null}
                </TooltipContent>
            </SideMenu>
        </TooltipWrapper>
    );
};

export default Tooltip;
