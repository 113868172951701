import styled from 'styled-components';
import { generateVwPercentageHeight } from '@src/helpers';
import colors from '@shared/colors';
import bgPattern from '@src/images/bg/home-black-gray-pattern.svg';
import { generateBreakpoint } from '../../layouts/grid';

export const StyledNewsItemTop = styled.div`
    padding: 4px 12px;
    padding-right: 0;

    font-family: SuisseIntl;

    letter-spacing: 0.24px;
    font-size: 12px;
    line-height: 1.33;

    color: ${colors.brownGrey};
    background-color: black;
    z-index: 2;

    text-transform: uppercase;

    > span {
        display: block;
        border-bottom: 1px solid ${colors.brownGrey};
    }

    ${generateBreakpoint('md')(
        `padding: 8px 20px;

        letter-spacing: 0.74px;
        border-bottom: 1px solid ${colors.brownGrey};

        > span {
            border-bottom: 0;
        }
        `,
    )}
`;

export const StyledNewItemTitle = styled.div`
    height: calc(100% - 32px);
    padding: 12px;

    display: flex;
    overflow: hidden;

    h3 {
        font-size: 20px;
        min-height: 3.6em;
        transform: translateY(0px);

        transition: transform 800ms cubic-bezier(0.860, 0.000, 0.070, 1.000) 500ms, opacity 1000ms cubic-bezier(1.000, 0.000, 0.880, 0.615) 300ms;
        opacity: 1;

        line-height: 1.2;
        letter-spacing: -0.6px;
        word-break: break-word;

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow-x: hidden;

        text-transform: uppercase;

        span {
            width: 100%;
            background-image: linear-gradient(transparent calc(100% - 2px), ${
                colors.violetBlue
            } 2px);
            background-repeat: no-repeat;
            transition: background-size 700ms ease-out;
            background-size: 0% 100%;
        }

        &:hover span {
            background-size: 100% 100%;
        }

        ${props =>
            props.animateStart
                ? `transform: translateY(200px); opacity: 0;`
                : ''}
    }

    ${generateBreakpoint('md')(
        `padding: 16px 20px;
        align-items: flex-end;

        h3 {
            min-height: 3.1em;
            vertical-align: bottom;
            font-size: 24px;
            line-height: 1;
            letter-spacing: -0.96px;
        }`,
    )}

    ${generateBreakpoint('lg')(
        `h3 {
            font-size: 28px;
            line-height: 1;
            letter-spacing: -0.96px;
        }`,
    )}

    ${generateBreakpoint('xl')(
        `h3 {
            font-size: 32px;
            line-height: 1;
            letter-spacing: -0.96px;
        }`,
    )}
`;

export const StyledNewsItem = styled.div`
    ${generateBreakpoint('md')(`border-bottom: 1px solid ${colors.brownGrey};`)}

    ${generateBreakpoint('lg')(
        `${generateVwPercentageHeight(220)}`,
    )}

    :nth-last-child(2) {
        border-bottom: 0;
    }
`;

export const StyledNewsList = styled.div`
    background-color: #000;
    border-right: 1px solid ${colors.brownGrey};
`;

export const StyledMobileBottomBlock = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    div {
        height: 32px;
    }

    div:first-child {
        background-color: ${colors.violetBlue};
    }

    div:last-child {
        background-image: url(${bgPattern});
        background-repeat: repeat;
    }

    ${generateBreakpoint('md')('display: none;')}
`;
