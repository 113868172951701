import styled from 'styled-components';
import { generateBreakpoint } from '../../layouts/grid';

export const Description = styled.div`
    font-family: 'SuisseIntl';

    font-size: 12px;
    line-height: 1.42;
    letter-spacing: 0.24px;
    padding-bottom: 16px;

    ${generateBreakpoint('lg')(
        `font-size: 14px;
    line-height: 1.21;
    letter-spacing: 0.28px;
    padding-bottom: 32px;`,
    )}
`;

export const TooltipWrapper = styled.div`
    position: absolute;
    bottom: 60px;
    right: 0;
`;

export const TooltipContent = styled.div`
    background-color: black;
    padding: 16px 20px;
    height: 160px;

    font-size: 14px;
    line-height: 1.21;
    letter-spacing: 0.28px;

    ${generateBreakpoint('lg')(`height: 200px;`)}

    > div:first-child > p {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;

        ${generateBreakpoint('lg')(`-webkit-line-clamp: 6;`)}
    }
`;
