import React from 'react';
import Image from '../image';
import {
    StyledFeaturedArtBlock,
    StyledCaption,
    StyledImage,
    StyledInnerCaption,
} from './index.styled';
import Tooltip from './tooltip';

const FeaturedArtBlock = ({
    relativeHeight,
    maxHeight,
    relativeWidth,
    maxWidth,
    artwork,
}) => {
    if (!artwork) {
        return null;
    }
    const { artist, image, imageSharp, title } = artwork;
    return (
        <StyledFeaturedArtBlock>
            <StyledImage
                relativeHeight={relativeHeight}
                maxHeight={maxHeight}
                relativeWidth={relativeWidth}
                maxWidth={maxWidth}
            >
                <Image image={image} imageSharp={imageSharp} />
            </StyledImage>
            <StyledCaption>
                <StyledInnerCaption>
                    {title && title.length ? <h2>⍉ {title[0].text}</h2> : null}
                    <div>\{artist}</div>
                </StyledInnerCaption>
            </StyledCaption>
            <Tooltip artwork={artwork} />
        </StyledFeaturedArtBlock>
    );
};

export default FeaturedArtBlock;
