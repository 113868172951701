import React from 'react';
import styled from 'styled-components';
import colors from '@shared/colors';
import { generateCol, generateGrid, generateBreakpoint } from '@layouts/grid';
import newsListBg from '@src/images/bg/news-list-bg.svg';
import bgPattern from '@src/images/bg/home-black-gray-pattern.svg';

const StyledTopCol = styled.div`
    ${generateGrid(12, 0)};

    > :nth-child(n) {
        padding-top: 24px;
        padding-bottom: 24px;

        ${generateBreakpoint('md')(
            `padding-top: 64px;
            padding-bottom: 0;`,
        )}
    }

    > :nth-child(1) {
        ${generateCol(12)}
        order: 3;
        padding-bottom: 0px;

        ${generateBreakpoint('md')(
            `order: 1;
            ${generateCol(3)}
            background-image: url(${newsListBg});
            background-repeat: no-repeat;`,
        )}
    }
    > :nth-child(2) {
        ${generateCol(6)}
        order: 1;
        background-color: ${colors.violetBlue};

        ${generateBreakpoint('md')(
            `order: 2;
            ${generateCol(3)}
            margin-right: -8px;`,
        )}
    }
    > :nth-child(3) {
        ${generateCol(6)}
        order: 2;
        background-image: url(${bgPattern});
        background-repeat: repeat;
        z-index: 2;

        ${generateBreakpoint('md')(
            `${generateCol(6)}
            order: 3;
            margin-left: 8px;`,
        )}
    }
`;

export const TopCol = ({ children }) => <StyledTopCol>{children}</StyledTopCol>;

export const BottomCol = styled.div`
    ${generateGrid(12, 0)}

    > :nth-child(n) {
        ${generateCol(12)}

        ${generateBreakpoint('lg')(generateCol(6))}
    }

    ${generateBreakpoint('lg')(
        `> :nth-child(1) {
            margin-right: -8px;
        }

        > :nth-child(2) {
            margin-left: 8px;

            border: 1px solid ${colors.brownGrey};
            border-bottom: 0;
            border-left: 0;

        }`,
    )}
`;
