import React, { createRef, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Image from '../image';
import { formatDate } from '@src/helpers';

import {
    StyledLatestRecordings,
    StyledHeadline,
    StyledRecording,
    StyledImage,
    StyledInnerRecording,
    InvertedWrapper,
} from './index.styled';
import { PlayIcon } from '../shared';
import { useLazy } from '../../helpers/hooks';

const Recording = ({
    animateStart,
    i,
    item: {
        title,
        show_image,
        show_imageSharp,
        date_start,
        _meta: { uid },
    },
    inverted,
}) => (
    <StyledRecording animateStart={animateStart} index={i} inverted={inverted}>
        <Link to={`/archive/${uid}`}>
            <StyledImage>
                <Image image={show_image} imageSharp={show_imageSharp} />
            </StyledImage>
            <StyledInnerRecording>
                {title.length ? (
                    <h3>
                        {title[0].text}
                        {inverted ? (
                            <InvertedWrapper>
                                <PlayIcon isBlack />
                                <PlayIcon />
                            </InvertedWrapper>
                        ) : (
                            <PlayIcon />
                        )}
                    </h3>
                ) : null}
                {date_start ? <span>{formatDate(date_start)}</span> : null}
            </StyledInnerRecording>
        </Link>
    </StyledRecording>
);

const filterLatestRecordings = items =>
    items.filter(i => !!i.audio_url || !!i.video_url).slice(0, 4);

const LatestRecordings = ({ items, inverted }) => {
    const ref = createRef();
    const loaded = useLazy(ref);
    const [animateStart, setAnimateStart] = useState(true);
    const [pageLoaded, setPageLoaded] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setPageLoaded(true);
        }, 500);
    }, []);
    useEffect(() => {
        if (pageLoaded && loaded) {
            setAnimateStart(false);
        }
    }, [loaded, pageLoaded]);
    const recordings = filterLatestRecordings(items || []);
    return (
        <StyledLatestRecordings inverted={inverted} ref={ref}>
            <StyledHeadline inverted={inverted}>
                <h2>\Latest Recordings</h2>
                <div>
                    <Link to="/archive">
                        <span className="m">View All Shows →</span>
                        <span className="d">Show More &#x25BA;</span>
                    </Link>
                </div>
            </StyledHeadline>
            {recordings.map((r, i) => (
                <Recording
                    key={`recording_${r._meta.uid}`}
                    i={i}
                    item={r}
                    inverted={inverted}
                    animateStart={animateStart}
                />
            ))}
        </StyledLatestRecordings>
    );
};

export default LatestRecordings;
