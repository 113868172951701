import styled from 'styled-components';
import {
    generateVwPercentageHeight,
    generateVwPercentageWidth,
} from '@src/helpers';
import { generateGrid, generateCol, generateBreakpoint } from '@layouts/grid';
import colors from '@shared/colors';

export const StyledLatestRecordings = styled.div`
    ${generateGrid()}
    position: relative;

    padding: 33px 0px;
    padding-top: 24px;
    padding-bottom: 90px;

    ${generateBreakpoint('sm')(`
        padding: 63px 20px;
        padding-top: 27px;
    `)}

    ${props =>
        props.inverted ? `border-top: 1px solid ${colors.brownGrey};` : null}
`;

export const StyledHeadline = styled.div`
    ${generateCol(12)}
    padding-bottom: 20px;

    h2 {
        font-size: 12px;
        line-height: 1.25;
        letter-spacing: 0.24px;
        text-transform: uppercase;
        color: ${colors.mauve};

        position: absolute;
        left: 12px;
        top: 184px;
        transform-origin: 0 0;
        text-align: right;
        transform: rotate(-90deg);

        ${generateBreakpoint('sm')(
            `position: relative;
            left: initial;
            top: initial;
            text-align: left;

            transform: none;
            font-size: 14px;
            line-height: 1.21;
            letter-spacing: 0.28px;
            color: ${colors.white};`,
        )}

        ${props =>
            props.inverted
                ? `
            color: #000;
            ${generateBreakpoint('sm')(`color: #000;`)}
        `
                : ''}
    }

    > div {
        position: absolute;
        bottom: 40px;
        right: 12px;

        font-size: 12px;
        line-height: 1.25;
        letter-spacing: normal;

        a,
        a:visited,
        a:active {
            display: block;
            color: ${colors.mauve};
            text-transform: uppercase;
            text-align: center;
            width: 80vw;
            padding: 13px 0;
            border: 1px solid ${colors.mauve};

            > span.d {
                display: none;
            }

            ${props => (props.inverted ? `color: #000;` : '')}
        }
    }

    ${generateBreakpoint('sm')(
        `position: relative;

        > div {
            top: 0;
            right: 8px;

            a,
            a:visited,
            a:active {
                display: inline-block;
                font-family: 'SuisseIntl';

                > span.d {
                    display: inline;
                }

                > span.m {
                    display: none;
                }

                border: 0;
                padding: 0;

                font-size: 14px;
                line-height: 1.29;
                letter-spacing: 0.28px;
                text-align: right;
                color: ${colors.brownGrey};
            }
        }`,
    )}
`;

export const StyledRecording = styled.div`
    position: relative;
    display: none;
    ${generateCol(12)}

    height: 100vw;
    width: 100vw;
    max-width: 100%;

    opacity: 1;
    transition: opacity 500ms cubic-bezier(0.550, 0.085, 0.680, 0.530);

    ${props =>
        props.inverted
            ? `a, a:visited, a:active {
        color: #000;
        ${generateBreakpoint('sm')('color: white;')}
    }`
            : ''}

    ${props => (props.index ? `transition-delay: ${props.index * 150}ms;` : '')}
    ${props => (props.animateStart ? `opacity: 0;` : '')}

    :nth-of-type(2) {
        display: block;
    }

    ${generateBreakpoint('sm')(
        `${generateCol(6)}
        height: calc(50vw - 8px - 20px);
        width: calc(50vw - 8px - 20px);
        border: 1px solid ${colors.brownGrey};

        :nth-of-type(3) {
            display: block;
        }`,
    )}

    ${generateBreakpoint('md')(
        `${generateCol(4)}
        height: calc(33.33vw - 24px);
        width: calc(33.33vw - 24px);

        :nth-of-type(4) {
            display: block;
        }`,
    )}

    ${generateBreakpoint('lg')(
        `${generateCol(3)}
        ${generateVwPercentageHeight(338, 507)}
        ${generateVwPercentageWidth(338, 507)}

        :nth-of-type(5) {
            display: block;
        }`,
    )}
`;

export const InvertedWrapper = styled.div`
    display: inline-block;

    > :first-child {
        ${generateBreakpoint('sm')('display: none;')}
    }
    > :last-child {
        display: none;
        ${generateBreakpoint('sm')('display: block;')}
    }
`;

export const StyledImage = styled.div`
    position: absolute;
    height: 80%;
    width: 80%;
    top: 0;
    right: 12px;
    filter: grayscale(100%);

    ${generateBreakpoint('sm')(
        `height: 100%;
        width: 100%;
        right: 0;`,
    )}
`;

export const StyledInnerRecording = styled.div`
    position: absolute;
    z-index: 2;
    bottom: 24px;
    left: 16.25vw;

    font-family: 'SuisseIntl';
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.24px;

    text-transform: uppercase;

    ${generateBreakpoint('sm')(
        `position: relative;
        padding: 10px 12px;
        bottom: initial;
        left: initial;

        font-size: 14px;
        line-height: 1.29;
        letter-spacing: 0.28px;`,
    )}

    h3 {
        margin-bottom: 5px;
    }
`;
