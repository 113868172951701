import styled from 'styled-components';
import colors from '@shared/colors';
import { generateBreakpoint } from '../layouts/grid';

export const HomeWrapper = styled.div``;

export const UpcomingWrapper = styled.div`
    position: relative;
    border-right: 1px solid ${colors.brownGrey};

    > div > div:nth-of-type(2) > div:first-child {
        transition-delay: 0.2s;
    }

    > div > div:nth-of-type(3) > div:first-child {
        transition-delay: 0.4s;
    }

    > div > div:nth-of-type(4) > div:first-child {
        transition-delay: 0.6s;
    }

    > div > div:nth-of-type(5) > div:first-child {
        transition-delay: 0.8s;
    }

    > div > div:nth-of-type(n + 3) {
        display: none;
    }

    > div > div:last-child {
        display: block;
    }

    ${generateBreakpoint('lg')(
        `> div > div:nth-of-type(3) {
            display: block;
        }`,
    )}

    ${generateBreakpoint('lg_xl')(
        `> div > div:nth-of-type(4) {
            display: block;
        }`,
    )}
`;

export const LatestRecordingsWrapper = styled.div`
    border-top: 1px solid ${colors.brownGrey};
    border-bottom: 1px solid ${colors.brownGrey};
`;

export const EmbedWrapper = styled.div`
    position: relative;
`;
